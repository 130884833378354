import React, { useState, useEffect } from "react";
import { Button } from "components/Button";
import { CenterDiv } from "components/CenterDiv";
import { ErrorMessage } from "components/ErrorMessage";
import { InputBox } from "components/InputBox";
import { Select } from "components/Select";
import { SuccessMessage } from "components/SuccessMessage";
import { availableDevicesList, configTemplatesList, createNetwork, organizationDetails } from "services/backend";
import { INetworkRequest } from "types/networkRequest";
import { ISelectOption } from "types/selectOption";
import { createOption, filterConfigTemplates, sampleTemplateVlanAddress, validateSerial, validateVlan } from "utils/functions";

export const NetworkForm = ({ accessToken }: any) => {
  const [cache, setCache] = useState<ISelectOption[] | null>(null);

  const [organizationArr, setOrganizationArr] = useState<ISelectOption[] | null>(null);
  const [configTemplatesArr, setConfigTemplatesArr] = useState<ISelectOption[] | null>(null);
  const [availableDevicesArr, setAvailableDevicesArr] = useState<ISelectOption[] | null>(null);

  const [organizationIndex, setOrganizationIndex] = useState<number>(-1);
  const [organization, setOrganization] = useState<ISelectOption | null>(null);
  const [configTemplate, setConfigTemplate] = useState<ISelectOption | null>(null);
  const [availableDevice, setAvailableDevice] = useState<ISelectOption | null>(null);

  const [networkName, setNetworkName] = useState<string>("");
  const [vlanAddressText, setVlanAddressText] = useState<string>("");
  const [vlanAddress, setVlanAddress] = useState<string>("");
  const [deviceName, setDeviceName] = useState<string>("");
  const [deviceAddress, setDeviceAddress] = useState<string>("");

  const [loadingOrg, setLoadingOrg] = useState<boolean>(false);
  const [loadingConfigTemplates, setLoadingConfigTemplates] = useState<boolean>(false);
  const [loadingAvailableDevices, setLoadingAvailableDevices] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const [requestError, setRequestError] = useState<JSX.Element>(<></>)
  const [createDeviceError, setCreateDeviceError] = useState<string>("")
  const [requestSuccess, setRequestSuccess] = useState<JSX.Element>(<></>)

  // Handle form submission
  const addUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRequestLoading(true);

    if (!validateVlan(vlanAddress)) {
      setRequestError(<><strong>Erro:</strong> Invalid VLAN address, check and try again</>);
      setRequestSuccess(<></>);
      setRequestLoading(false);
      return;
    }

    const request: INetworkRequest = {
      organizationId: organization?.value.id,
      networkName,
      configTemplate: configTemplate?.value,
      vlanAddress: parseInt(vlanAddress),
      deviceSerial: availableDevice?.value.serial,
      deviceName,
      deviceAddress,
      newDevice: availableDevice?.value.claimedAt ? false : true,
    };

    await createNetwork(request, accessToken)
      .then((res) => {
        if (res.status !== 201) {
          setRequestError(<><strong>Erro:</strong> {res.data.message[0]}</>);
          setRequestSuccess(<></>);
          setRequestLoading(false);
        } else if (res.data.error) {
          setRequestError(<><strong>Erro:</strong> {res.data.message[0]}</>);
          setRequestSuccess(<></>);
          setRequestLoading(false);
        } else {
          setRequestError(<></>);
          setRequestSuccess(
            <>
              <strong>Rede criada com sucesso!</strong>
              <p>Nome da rede: {request.networkName}</p>
              <p>Template: {request.configTemplate.name}</p>
              <p>Dispositivo: {request.deviceName}</p>
              <p>S/N: {request.deviceSerial}</p>
              {configTemplate?.value.name !== "TEMP_Z3" ? <p>Endereço: {request.deviceAddress}</p> : null}
            </>
          );
          clearForm();
          setRequestLoading(false);
        }
      });
  };

  // Fetch organization details (cache/request)
  useEffect(() => {
    if (!cache && accessToken) {
      setLoadingOrg(true);
      organizationDetails(accessToken).then((res) => {
        const getOrg = res.data.filter((item: any) => item.label === "Cresol Confederação");
        setCache(getOrg);
        setOrganizationArr(getOrg);
        setLoadingOrg(false);
      });
    } else {
      setOrganizationArr(cache);
    }
  }, [cache, accessToken]);

  // Fetch config templates when organization is selected
  useEffect(() => {
    const fetchConfigTemplates = async () => {
      if (organization && accessToken && !organization.value.configTemplates) {
        setLoadingConfigTemplates(true);
        const res = await configTemplatesList(organization.value.id, accessToken);
        const filteredTemplates = filterConfigTemplates(res.data);
        setConfigTemplatesArr(filteredTemplates);
        const updatedCache = cache?.map((org) => {
          if (org.value.id === organization.value.id) {
            org.value.configTemplates = filteredTemplates;
          }
          return org;
        });
        setCache(updatedCache || []);
        setLoadingConfigTemplates(false);
      }
    };

    if (organization) {
      fetchConfigTemplates();
    }
  }, [organization, cache, accessToken]);

  // Fetch available devices when organization and config template are selected
  useEffect(() => {
    const fetchAvailableDevices = async () => {
      if (organization && configTemplate && accessToken && !organization.value.availableDevices) {
        setLoadingAvailableDevices(true);
        const res = await availableDevicesList(organization.value.id, accessToken);
        const updatedCache = cache?.map((org) => {
          if (org.value.id === organization.value.id) {
            org.value.availableDevices = res.data;
          }
          return org;
        });
        setCache(updatedCache || []);
        setAvailableDevicesArr(res.data);
        setLoadingAvailableDevices(false);
      }
    };

    if (organization && configTemplate) {
      fetchAvailableDevices();
    }
  }, [organization, configTemplate, cache, accessToken]);

  useEffect(() => {
    if (configTemplate) {
      setVlanAddressText(sampleTemplateVlanAddress(vlanAddress, configTemplate.label));
    }
  }, [vlanAddress, configTemplate]);

  const selectOrganization = (option: ISelectOption | null) => {
    setOrganization(option);
    setConfigTemplate(null);
    setConfigTemplatesArr(null);
  };

  const selectConfigTemplate = (option: ISelectOption | null) => {
    setConfigTemplate(option);
  };

  const handleDeviceCreate = (inputValue: string) => {
    setCreateDeviceError("");
    setLoadingAvailableDevices(true);
    if (validateSerial(inputValue) === false) {
      setCreateDeviceError(`O serial "${inputValue.toUpperCase()}" é inválido, o serial deve seguir o padrão "XXXX-XXXX-XXXX"`);
      setLoadingAvailableDevices(false);
    } else {
      setTimeout(() => {
        const newOption = createOption(inputValue);
        setLoadingAvailableDevices(false);
        setAvailableDevicesArr((prev) => prev ? [...prev, newOption] : [newOption]);
        setAvailableDevice(newOption);
      }, 1000);
    }
  };

  const clearForm = () => {
    setConfigTemplate(null);
    setNetworkName("");
    setVlanAddress("");
    setAvailableDevice(null);
    setDeviceName("");
    setDeviceAddress("");
  };

  useEffect(() => {
    if (organizationArr) {
      setOrganization(organizationArr[0]);
    }
  }, [organizationArr]);

  return (
    <form onSubmit={addUser}>
      <Select
        isClearable={true}
        value={organization}
        placeholder={"Selecione a organização..."}
        options={organizationArr ? organizationArr : []}
        onChange={selectOrganization}
        isLoading={loadingOrg}
        isDisabled={true}
      />
      <Select
        isClearable={true}
        value={configTemplate}
        placeholder={"Selecione o template..."}
        options={configTemplatesArr ? configTemplatesArr : []}
        isLoading={loadingConfigTemplates}
        onChange={selectConfigTemplate}
        isDisabled={organization ? false : true}
      />
      <InputBox
        placeholder={"Nome da rede"}
        article="o"
        value={networkName}
        onChange={(event: any) => setNetworkName(event.target.value)}
        type="text"
      />
      <InputBox
        placeholder={"Endereço da VLAN"}
        article="o"
        value={vlanAddress}
        isDisabled={configTemplate ? false : true}
        onChange={(event: any) => setVlanAddress(event.target.value)}
        type="text"
        maxLength={3}
      />
      {configTemplate && vlanAddress.length ? <CenterDiv>Exemplo do padrão ({configTemplate.label}): {vlanAddressText}</CenterDiv> : null}
      <Select
        placeholder={"Selecione o dispositivo..."}
        isClearable={true}
        isDisabled={organization ? false : true}
        isLoading={loadingAvailableDevices}
        onChange={(newValue: ISelectOption) => setAvailableDevice(newValue)}
        onCreateOption={handleDeviceCreate}
        options={availableDevicesArr ? availableDevicesArr : []}
        value={availableDevice}
      />
      <ErrorMessage>{createDeviceError}</ErrorMessage>
      <InputBox
        placeholder={"Nome do dispositivo"}
        article="o"
        value={deviceName}
        onChange={(event: any) => setDeviceName(event.target.value)}
        type="text"
      />
      <InputBox
        placeholder={"Endereço do dispositivo"}
        article="o"
        value={deviceAddress}
        onChange={(event: any) => setDeviceAddress(event.target.value)}
        type="text"
        isDisabled={configTemplate?.value.name === "TEMP_Z3"}
      />

      {
        !requestLoading ?
          <Button type="submit" disabled={!organization || !networkName || !configTemplate || !availableDevice || !deviceName || requestLoading}>Criar</Button>
          :
          <Button type="submit" disabled={true}>Criar</Button>}
      {
        requestLoading ?
          <CenterDiv>Carregando...</CenterDiv>
          :
          <>
            <ErrorMessage>{requestError}</ErrorMessage>
            <SuccessMessage>{requestSuccess}</SuccessMessage>
          </>
      }
    </form>
  );
};
